import React from "react"
import { graphql } from "gatsby"

import { labels, localeUrls } from '../langs/langs'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ForSellers from "../components/ForSellers"
import PartnersSlider from "../components/PartnersSlider";
import { FiveSellersPoints } from "./pre-predajcov.sk";
import ContactForm from "../components/ContactForm";

const ForSellersENPage = ({ location }) => {

    const localeID = 'en_US';

    return (
        <Layout location={location} localeID={localeID}>
            <Seo title={labels[localeID]["FOR_SELLERS"]} description={labels[localeID]["FOR_SELLERS"]} />
            <ForSellers localeID={localeID}/>
            <FiveSellersPoints localeID={localeID}/>
            <ContactForm localeID={localeID}/>
            <PartnersSlider className="gray" localeID={localeID}/>
        </Layout>
    )
  }
  
export default ForSellersENPage

